import { Box, Text } from '@lendoab/aphrodite';
import React from 'react';

import styles from './UnknownError.module.scss';

export default function UnknownError() {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            paddingX={['medium', '2xl', '2xl']}
            paddingY={['medium', '2xl', '2xl']}
            className={styles.formNotSent}
        >
            <Box marginBottom="medium">
                <CrossIconComponent />
            </Box>
            <Text component="h1" weight="bold" align="center" size={['medium', 'large']} marginBottom="medium">
                Tekniskt fel
            </Text>
            <Text align="center" size="xs" marginBottom="2xl">
                Var god försök ladda om sidan. Om felet kvarstår kontakta vår kundtjänst så hjälper vi dig.
            </Text>
        </Box>
    );
}

function CrossIconComponent(props) {
    const { ...rest } = props;

    return (
        <svg
            {...rest}
            viewBox="0 0 97 96"
            style={{
                display: 'block',
                width: '96px',
                margin: '0 auto',
            }}
            className={styles.animate}
        >
            <circle
                className={styles.circle}
                strokeWidth="8"
                fill="none"
                transform="rotate(90 48 48)"
                cx="48"
                cy="48"
                r="44"
            ></circle>
            <path
                className={styles.cross}
                d="M44.8809524,23.9880952 C45.6354204,23.9880952 46.2883157,24.2637621 46.8396577,24.8151042 C47.3909998,25.3664462 47.6666667,26.0193415 47.6666667,26.7738095 L47.6666667,29.5595238 C47.6666667,30.3139919 47.3909998,30.9668871 46.8396577,31.5182292 C46.2883157,32.0695712 45.6354204,32.3452381 44.8809524,32.3452381 L32.3452381,32.3452381 L32.3452381,44.8809524 C32.3452381,45.6354204 32.0695712,46.2883157 31.5182292,46.8396577 C30.9668871,47.3909998 30.3139919,47.6666667 29.5595238,47.6666667 L26.7738095,47.6666667 C26.0193415,47.6666667 25.3664462,47.3909998 24.8151042,46.8396577 C24.2637621,46.2883157 23.9880952,45.6354204 23.9880952,44.8809524 L23.9880952,32.3452381 L11.452381,32.3452381 C10.6979129,32.3452381 10.0450176,32.0695712 9.4936756,31.5182292 C8.94233355,30.9668871 8.66666667,30.3139919 8.66666667,29.5595238 L8.66666667,26.7738095 C8.66666667,26.0193415 8.94233355,25.3664462 9.4936756,24.8151042 C10.0450176,24.2637621 10.6979129,23.9880952 11.452381,23.9880952 L23.9880952,23.9880952 L23.9880952,11.452381 C23.9880952,10.6979129 24.2637621,10.0450176 24.8151042,9.4936756 C25.3664462,8.94233355 26.0193415,8.66666667 26.7738095,8.66666667 L29.5595238,8.66666667 C30.3139919,8.66666667 30.9668871,8.94233355 31.5182292,9.4936756 C32.0695712,10.0450176 32.3452381,10.6979129 32.3452381,11.452381 L32.3452381,23.9880952 L44.8809524,23.9880952 Z"
            ></path>
        </svg>
    );
}
