import Head from 'next/head';
import React, { ReactElement } from 'react';

const siteData = {
    title: 'Inbox | Lendo',
    description: 'Inbox',
    siteUrl: 'https://inbox.lendo.se',
};

export function SEO(props: { title?: string; description?: string; pathname?: string }): ReactElement {
    const title = props.title || siteData.title;
    const description = props.description || siteData.description;
    const url = props.pathname ? `${window.location.hostname}${props.pathname}` : siteData.siteUrl;

    return (
        <Head>
            <title>{title}</title>
            <meta name="description" content={description} />
            <link rel="canonical" href={url} />
        </Head>
    );
}
