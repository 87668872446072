import React, { Fragment } from 'react';

function Bubbles() {
    return (
        <Fragment>
            <svg
                viewBox="0 0 534 464"
                style={{
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    width: '20vw',
                    overflow: 'visible',
                    transform: 'translate(-25%, 20%)',
                    zIndex: 0,
                }}
            >
                <defs>
                    <linearGradient
                        x1="51.7204771%"
                        y1="40.589172%"
                        x2="64.9334253%"
                        y2="7.21892722%"
                        id="linearGradient-1"
                    >
                        <stop stopColor="#F2F6FF" stopOpacity="0" offset="0%"></stop>
                        <stop stopColor="#E0E9FE" offset="100%"></stop>
                    </linearGradient>
                </defs>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.8">
                    <path
                        d="M522.358532,389.055916 C475.759665,562.13329 336.998723,700.977188 221.843862,826.103286 C219.81883,721.773566 185.358581,632.076979 114.557695,558.966236 C53.2744567,495.686175 1.61879691,453.983578 -19.0423099,359.612687 C-69.7490978,127.960067 166.520002,-71.6375706 370.706818,24.8874052 C502.166075,87.0324523 560.87463,246.007586 522.358532,389.055916 Z"
                        fill="url(#linearGradient-1)"
                    ></path>
                </g>
            </svg>
            <svg
                viewBox="0 0 534 464"
                style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    width: '30vw',
                    overflow: 'visible',
                    transform: 'translate(45%, -100%)',
                    zIndex: 0,
                }}
            >
                <defs>
                    <linearGradient x1="46.0012731%" y1="30.5402011%" x2="27.0114495%" y2="50%" id="linearGradient-2">
                        <stop stopColor="#F2F6FF" stopOpacity="0" offset="0%"></stop>
                        <stop stopColor="#E0E9FE" offset="100%"></stop>
                    </linearGradient>
                </defs>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.8">
                    <path
                        d="M601.435872,-68.2790894 C800.737496,25.9118374 889.743859,266.864511 831.350703,483.677522 C760.703492,746.004432 550.331992,956.444942 375.748998,1146.0939 C372.678905,987.965237 320.434705,852.015469 213.095505,741.204246 C120.185739,645.293022 41.8720689,582.085953 10.5483552,439.051518 C-66.3267572,87.9442947 291.874075,-214.57839 601.435872,-68.2790894"
                        id="Bubble"
                        fill="url(#linearGradient-2)"
                    ></path>
                </g>
            </svg>
        </Fragment>
    );
}

export default Bubbles;
