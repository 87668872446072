import BankId from '@frontend/bank-id';
import { BankIdIcon, Box, Button, Card, Text } from '@lendoab/aphrodite';
import * as Sentry from '@sentry/react';
import { Analytics } from 'APP/Analytics';
import { AuthData, useAuth } from 'APP/Authentication';
import { pushUIInteraction } from 'APP/components/Login/LoginHelpers';
import { isNotProduction } from 'APP/helpers/envHelpers';
import React, { ReactElement, useState } from 'react';

import styles from './Login.module.scss';

type ViewProps = {
    setShowBankId: (value: boolean) => void;
};

const DATALAYER_CONTEXT = 'login';

export default function Login(): ReactElement {
    const [showBankId, setShowBankId] = useState(false);

    return showBankId ? <BankIDView setShowBankId={setShowBankId} /> : <LogInView setShowBankId={setShowBankId} />;
}

function LogInView({ setShowBankId }: ViewProps): ReactElement {
    return (
        <Box
            position="relative"
            className={styles.container}
            marginX="auto"
            paddingX="medium"
            paddingBottom={['none', '5xl']}
            paddingTop={['none', '5xl']}
        >
            <Text marginBottom="medium" color="dark" size="large" weight="bold">
                Logga in på Mina sidor
            </Text>

            <Card padding={['xl', '2xl', '2xl']} borderRadius="small">
                <Text size="xs" color="dark" marginBottom="medium">
                    Har du gjort en ansökan hos oss? Logga in med BankID här för att se och hantera dina erbjudanden!
                </Text>
                <Button type="button" onClick={() => setShowBankId(true)} fluid primary size="medium">
                    Logga in
                    <BankIdIcon />
                </Button>
            </Card>

            <Box display="flex" alignItems="center" flexDirection="column" marginY="2xl">
                <Text align="center" size="xs" component="label">
                    Är du ny hos Lendo eller har du ingen aktiv ansökan?{' '}
                    <Text
                        align="center"
                        size="xs"
                        color="blue"
                        display={['inline', 'inline', 'block']}
                        component="a"
                        href="https://lendo.se/"
                        fluid
                        onClick={() => {
                            pushUIInteraction();
                        }}
                    >
                        Jämför lån nu!
                    </Text>
                </Text>
            </Box>
        </Box>
    );
}

function BankIDView({ setShowBankId }: ViewProps): ReactElement {
    const { bankIdAuth } = useAuth();

    return (
        <Box position="relative" className={styles.container} marginX="auto" paddingX="medium">
            <Card padding="none" borderRadius="small">
                <BankId
                    action="auth"
                    endpoint="auth/bankid-auth"
                    devMode={isNotProduction()}
                    onBack={(): void => {
                        setShowBankId(false);
                    }}
                    onVerified={(data): void => {
                        bankIdAuth(data as AuthData);
                    }}
                    onError={(error): void => {
                        Sentry.captureException(error);
                    }}
                    onEvent={(data): void => {
                        const { event, payload } = data;

                        switch (event) {
                            case 'VERIFIED':
                                Analytics.pushBankIdProgress('bankIdSuccess', {
                                    context: DATALAYER_CONTEXT,
                                    method: payload.trackingMethod,
                                });
                                break;
                            case 'ERROR_OCCURRED':
                                Analytics.pushBankIdProgress('bankIdFailure', {
                                    context: DATALAYER_CONTEXT,
                                    method: payload.trackingMethod,
                                    reason: payload.error,
                                });
                                break;
                            case 'INITIATED':
                                Analytics.pushBankIdProgress('bankIdInit', {
                                    context: DATALAYER_CONTEXT,
                                    method: payload.trackingMethod,
                                });
                                break;
                        }
                    }}
                />
            </Card>
        </Box>
    );
}
