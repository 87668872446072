import { Box } from '@lendoab/aphrodite';
import { ErrorBoundary } from '@sentry/react';
import { Analytics } from 'APP/Analytics';
import Bubbles from 'APP/assets/Bubbles';
import { LoginLoader } from 'APP/components/Loaders/LoginLoader';
import Login from 'APP/components/Login/Login';
import UnknownError from 'APP/components/Messages/UnknownError';
import { SEO } from 'APP/components/SEO';
import { ApplicationConstants } from 'APP/constants/ApplicationConstants';
import { getSessionItem, setSessionItem } from 'APP/helpers/SessionHelpers';
import { Routes } from 'APP/Routes';
import Router, { useRouter } from 'next/router';
import React, { ReactElement, useEffect, useRef } from 'react';

import { useAuth } from '../Authentication';

export default function IndexPage(): ReactElement {
    const tracked = useRef(false);
    const { asPath } = useRouter();
    const { state } = useAuth();

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        setSessionItem('msg_action', searchParams.get('msg_action') || '');
        setSessionItem('msg_medium', searchParams.get('msg_medium') || '');
        setSessionItem('msg_template', searchParams.get('msg_template') || '');
    }, []);

    useEffect(() => {
        document.title = 'Inbox | Lendo';
        if (!tracked.current && state.status === 'idle') {
            Analytics.pushPageView({
                location: `${window.location.hostname}${asPath}`,
                path: asPath,
                product: ApplicationConstants.TYPE.PRIVATE,
                title: document.title,
                trackingId: undefined,
                msg_medium: getSessionItem('msg_medium'),
                msg_action: getSessionItem('msg_action'),
                msg_template: getSessionItem('msg_template'),
            });

            tracked.current = true;
        }
    }, [state.status, asPath]);

    useEffect(() => {
        if (state.status === 'authenticated') {
            Router.push(Routes.getApplicationsRoute());
        }
    }, [state.status]);

    return (
        <Box
            position="relative"
            paddingBottom={['6xl', '8xl']}
            paddingTop={['6xl', '8xl']}
            display="flex"
            justifyContent="center"
            alignItems="center"
            fluid
        >
            <SEO />

            {['pending_validate', 'pending_url_token', 'authenticated'].some(status => state.status === status) ? (
                <LoginLoader />
            ) : null}

            {['idle', 'rejected'].some(status => state.status === status) ? (
                <ErrorBoundary fallback={UnknownError}>
                    <Login />
                </ErrorBoundary>
            ) : null}

            <Box
                display={['none', 'block', 'block']}
                position="absolute"
                fluid
                style={{ overflow: 'hidden', top: 0, left: 0, height: '100%', pointerEvents: 'none' }}
            >
                <Bubbles />
            </Box>
        </Box>
    );
}
