export function getSessionItem(key: string): undefined | string | null {
    try {
        return sessionStorage.getItem(key);
    } catch (err: unknown) {
        return undefined;
    }
}

export function setSessionItem(key: string, value: string): void | null {
    if (!value) {
        return null;
    }

    try {
        return sessionStorage.setItem(key, value);
    } catch (err: unknown) {
        console.error(err);
        return null;
    }
}
